var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upr-mobile" },
    [
      _c("UnconditionalPositiveRegardStage", {
        staticClass: "upr-mobile__stage",
        attrs: { draggable: "", userID: _vm.viewerId },
      }),
      _c("TextField", {
        staticClass: "upr__field",
        attrs: {
          size: "sm",
          placeholder: "Enter here…",
          show: "show",
          status: "active",
          buttonText: "Add",
          allowFreeSubmit: "",
          maxTextLength: 12,
        },
        on: { onSubmit: _vm.onSubmit },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }