





















import { defineComponent } from "@vue/composition-api"

import { useViewer } from "@/use"
import TextField from "@/components/GroupTeams/Common/Games/TextField.vue"

import UnconditionalPositiveRegardStage from "./UnconditionalPositiveRegardStage.vue"
import useUPRRef from "./useUPRRef"

export default defineComponent({
  name: "UnconditionalPositiveRegardMobile",
  components: {
    UnconditionalPositiveRegardStage,
    TextField
  },
  setup() {
    const { id: viewerId } = useViewer()
    const { onSubmit } = useUPRRef(viewerId)

    return {
      onSubmit,
      viewerId
    }
  }
})
